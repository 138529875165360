<template>
  <div id="f-alert-campaign" style="display: none;">
    <b-alert v-if="state.alert" :show="state.alert" variant="campaign" dismissible :dismiss-label="$t('txt.close')"
      @dismissed="state.alert = false; cookie('w', 'f-alert-campaign', true, 604800000);">
      <b-row class="mx-0">
        <b-col cols="0" sm="3" class="d-none d-sm-flex justify-content-end pr-2 py-2">
          <img alt="" :src="`${$t('baseurl')}img/com/c24/characters-balloon.png`" width="185" height="126" />
        </b-col>
        <b-col cols="12" sm="9"
          class="align-self-center mt-2 mt-md-0 px-4 px-md-0 py-2 d-flex flex-wrap justify-content-center justify-content-md-start flex-column align-items-center">
          <div v-html="$t(`alert.campaign[${active}].md`, { days })"></div>
          <div class="progress-button-wrapper">
            <div class="progress vertical position-relative">
              <div ref="progressThermometer" class="progress-bar progress-bar-info" role="progressbar" aria-valuenow="0"
                aria-valuemin="0" aria-valuemax="100" style="width: 0%;" />
              <span ref="stepInfo" id="step-info" class="position-absolute"></span>
            </div>
            <a class="btn btn-sm btn-warning mt-1 mt-sm-0 ml-sm-1"
              :href="`${$t('link.soutenir')}?mtm_campaign=Campagne2024&mtm_source=nav&mtm_cid=${active + 1}`">
              <b v-html="$t(`alert.campaign[${active}].btn`)"></b>
              <i class="fas fa-chevron-right"></i>
            </a>
          </div>
        </b-col>
      </b-row>
    </b-alert>
  </div>
</template>

<script>
export default {
  data() {
    let active = 1;
    const blog = this.$t('lang') === 'fr' ? [
      { date: '2024/11/19' }, // intro
      { date: '2024/11/26' }, // space
      { date: '2024/12/03' }, // ia
      { date: '2024/12/10' }, // app pt
      { date: '2024/12/17' }, // pt
      { date: '2024/12/24' }, // 20ans
    ] : [
      { date: '2024/11/19' }, // basic
      { date: '2024/12/10' }, // peertube
      { date: '2024/12/14' }, // basic
    ];

    for (let i = 0; i < blog.length; i += 1) {
      if (this.is.after(blog[i].date)) {
        active = i;
      }
    }

    const end = (new Date('12/31/2024')).getTime();
    const now = (new Date()).getTime();
    const days = ((end - now) / (1000 * 3600 * 24) + 0.5).toFixed(0);

    return {
      active,
      state: {
        alert: !this.cookie('r', 'f-alert-campaign'),
      },
      days,
      donations: 0,
      max: 400000,
      numberFormat: new Intl.NumberFormat(undefined, { style: "currency", currency: "EUR", roundingMode: 'ceil', maximumFractionDigits: 0 }),
    };
  },
  async created() {
    const data = await (await fetch('https://soutenir.framasoft.org/campagne-fin-2024.json', { cache: 'no-cache' })).json();
    this.donations = data.total;
  },
  computed: {
    percent() {
      return Math.max(Math.round(100 * this.donations / this.max), 3);
    }
  },
  watch: {
    donations(newValue) {
      if (!newValue || !this.$refs.stepInfo || !this.$refs.progressThermometer) return;
      this.$refs.stepInfo.innerHTML = ` 🎈${this.numberFormat.format(newValue)}🎈`;
      this.$refs.progressThermometer.style.width = `${this.percent}%`;
      this.$refs.progressThermometer.setAttribute("aria-valuenow", this.percent);
    }
  }
};
</script>

<style lang="scss">
#f-- #fp-alert .alert-campaign {
  border: none;
  padding: 0;
  color: var(--f-g10);

  .row {
    background: var(--f-o1) url('../../../public/img/com/c24/bg.png') repeat-y center bottom;
    border-radius: .25rem;
    box-shadow: .125rem .125rem .25rem var(--f-g5);
  }

  .close {
    color: var(--f-g10);
    padding: .25rem 0;
  }

  img {
    height: 100%;
    max-height: 126px;
    margin-left: auto;
    display: block;
    align-self: center;
  }

  div {
    h1 {
      text-align: left;
      color: var(--f-g10);
      font-family: 'Tovari Sans';
      font-size: 1.75rem;
      line-height: 1.75rem;
      margin: 0 0 .5rem 0;
    }

    p {
      color: var(--f-g10);
      margin: 0 0 .75rem 0;
    }
  }

  .progress-button-wrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }

  #step-info {
    top: 12px;
    left: 120px;
    color: black;
  }

  .progress {
    display: flex;
    overflow: hidden;
    line-height: 0;
    font-size: .85rem;
    background-color: #e9ecef;
    height: 1.5rem;
    width: 20rem;
    border-radius: 20px !important;
    box-shadow: inset 0 4px 6px #ccc;
    max-width: 85vw;
  }

  .progress-bar {
    flex-direction: column;
    justify-content: center;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    background-color: #fc8e3a;
    transition: width .6s ease;
    display: flex;
    overflow: hidden;
    box-shadow: inset 0 4px 6px hsla(0, 0%, 39.2%, .6);
  }
}

[data-url*="://soutenir.framasoft.org"],
[data-url*="://framasoft.org"],
[data-url*=".frama.io"],
[data-url*="://20ans.framasoft.org"] {
  #fp-alert {
    display: none !important;
  }
}

[data-url^="https://framanav"][data-url$="frama.io/"] {
  #fp-alert {
    display: block !important;
  }
}
</style>
